import React, { Component } from 'react';
import { toast } from 'react-toastify';
import PubSub from 'pubsub-js';
import moment from 'moment-mini';
import AccountManager from '../../managers/Account';
import Throbber from '../throbber';
import BirthDate from './BirthDate';

const emailPattern = /^[-a-z0-9~!$%^&*_=+}{'?]+(\.[-a-z0-9~!$%^&*_=+}{'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;
const passwordRequirements = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;

class AccountDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountDetails: null,
      isLoading: false,
      updateFirstname: '',
      updateLastname: '',
      updateEmail: '',
      updateUsername: '',
      updateTelephone: '',
      updatePassword: '',
      updateConfirmPassword: '',
      updateBirthDate: '',
      startingBirthDate: '',
    };
  }

  async componentDidMount() {
    // get the account details from the AccountManager
    // we can assume that the user is already logged in here
    const accountData = await AccountManager.get(AccountManager.getToken());

    this.setState({
      accountDetails: accountData,
      updateFirstname: accountData.firstName,
      updateLastname: accountData.lastName,
      updateEmail: accountData.email,
      updateUsername: accountData.username,
      updateTelephone: accountData.telephone,
      startingBirthDate: moment(accountData.dateOfBirth).format('YYYY-MM-DD') || '1985-07-03',
    });
  }

  onEnterKey = (e) => {
    if (e.keyCode === 13) {
      this.validateForm();
    }
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  }

  validateForm = () => {
    const {
      updateFirstname, updateLastname, updateEmail, updateUsername, updateTelephone, updatePassword, updateConfirmPassword, accountDetails, updateBirthDate,
    } = this.state;

    // if the user is only updating their birthdate, we don't need to validate the other fields
    if (updateEmail
      && !updateFirstname
      && !updateLastname
      && !updateUsername
      && !updateTelephone) {
      return true;
    }

    if (updateEmail === ''
      || updateFirstname === ''
      || updateLastname === ''
      || updateUsername === ''
      || updateTelephone === '') {
      toast.error('Please fill out all fields.');
      return false;
    }
    if (!emailPattern.test(updateEmail)) {
      toast.error('Please enter a valid email address.');
      return false;
    }
    if (!accountDetails.isVerified) {
      if (updatePassword !== updateConfirmPassword) {
        toast.error('Passwords don\'t match.');
        return false;
      }
      if (!passwordRequirements.test(updatePassword)) {
        toast.error('Passwords must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters');
        return false;
      }
    }
    return true;
  }

  updateDetails = async (e) => {
    const {
      updateFirstname, updateLastname, updateEmail, updateUsername, updateTelephone, updatePassword, updateBirthDate,
    } = this.state;
    e.preventDefault();
    let payload = {};
    payload = {
      email: updateEmail,
      firstname: updateFirstname,
      lastname: updateLastname,
      username: updateUsername,
      telephone: updateTelephone,
      password: updatePassword,
    };
    if (updateBirthDate) {
      payload.dateOfBirth = updateBirthDate;
    }
    if (this.validateForm()) {
      this.setState({
        isLoading: true,
      });
      const accountChanges = await AccountManager.update(payload, AccountManager.getToken());
      const freshAccountDetails = await AccountManager.get(AccountManager.getToken(), true);
      if (accountChanges && freshAccountDetails) {
        this.setState({
          accountDetails: freshAccountDetails,
          isLoading: false,
        });
        PubSub.publish('account_updated');
        toast.success('Account saved!');
      }
    }
  }

  setUserDate = (date) => {
    this.setState({
      updateBirthDate: date,
    });
  }

  render() {
    const { accountDetails } = this.state;
    const { updateFirstname } = this.state;
    const { updateLastname } = this.state;
    const { updateEmail } = this.state;
    const { updateTelephone } = this.state;
    const { updateUsername } = this.state;
    const { isLoading } = this.state;
    const { updatePassword } = this.state;
    const { updateConfirmPassword } = this.state;
    const { startingBirthDate } = this.state;
    return (
      <div>
        {accountDetails
          && (
            <div>
              <div className="account-box -account-section">
                {isLoading
                && <Throbber throbberText="Updating your details! Please stand by..." />}
                <h2 className="account-heading__h2">
                  Contact Details
                </h2>
                <div className="account-subheading">
                  View and update your contact details here
                </div>
                <div className="form-container">
                  <div className="form-section">
                    <label className="label-block" htmlFor="updateFirstname">First name</label>
                    <input
                      className="input-block"
                      id="updateFirstname"
                      type="text"
                      value={updateFirstname}
                      onChange={(e) => this.handleInputChange(e)}
                      onKeyUp={(e) => this.onEnterKey(e)} />
                  </div>
                  <div className="form-section">
                    <label className="label-block" htmlFor="updateLastname">Last name</label>
                    <input
                      className="input-block"
                      id="updateLastname"
                      type="text"
                      value={updateLastname}
                      onChange={(e) => this.handleInputChange(e)}
                      onKeyUp={(e) => this.onEnterKey(e)} />
                  </div>
                  <div className="form-section">
                    <label className="label-block" htmlFor="updateEmail">Email</label>
                    <input
                      className="input-block"
                      id="updateEmail"
                      type="email"
                      value={updateEmail}
                      onChange={(e) => this.handleInputChange(e)}
                      onKeyUp={(e) => this.onEnterKey(e)} />
                  </div>
                  <div className="form-section">
                    <label className="label-block" htmlFor="updateUsername">Username</label>
                    <input
                      className="input-block"
                      id="updateUsername"
                      type="text"
                      value={updateUsername}
                      onChange={(e) => this.handleInputChange(e)}
                      onKeyUp={(e) => this.onEnterKey(e)} />
                  </div>
                  {!accountDetails.isVerified
                  && (
                  <div>
                    <div className="form-section">
                      <label className="label-block" htmlFor="registerPassword">Password</label>
                      <input
                      className="input-block"
                      id="updatePassword"
                      autoComplete="new-password"
                      type="password"
                      value={updatePassword}
                      onChange={(e) => this.handleInputChange(e)}
                      onKeyUp={(e) => this.onEnterKey(e)} />
                      <div className="form-requirements">Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters</div>
                    </div>
                    <div className="form-section">
                      <label className="label-block" htmlFor="registerConfirmPassword">Confirm password</label>
                      <input
                      className="input-block"
                      id="updateConfirmPassword"
                      type="password"
                      value={updateConfirmPassword}
                      onChange={(e) => this.handleInputChange(e)}
                      onKeyUp={(e) => this.onEnterKey(e)} />
                    </div>
                  </div>
                  )}
                  <div className="form-section">
                    <label className="label-block" htmlFor="updateTelephone">Phone number</label>
                    <input
                      className="input-block"
                      id="updateTelephone"
                      type="text"
                      value={updateTelephone}
                      onChange={(e) => this.handleInputChange(e)}
                      onKeyUp={(e) => this.onEnterKey(e)} />
                  </div>
                  {!process.env.CUSTOM_BIRTHDATE_ENTRY_LINK
                  && (
                  <div className="form-section">
                    <label className="label-block" htmlFor="updateBirthDate">Date of birth</label>
                    <BirthDate onSelectedDate={(date) => this.setUserDate(date)} startingDate={startingBirthDate} />
                    <div className="form-requirements">Your date of birth can only be entered once.</div>
                  </div>
                  )}
                  <div className="form-cta">
                    <button
                      className="button"
                      type="submit"
                      onClick={(e) => this.updateDetails(e)}>
                      Update Details
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>
    );
  }
}

export default AccountDetails;
