import PropTypes from 'prop-types';
import React from 'react';

export default class SimpleModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  closeModal(e) {
    const { onBeforeClose } = this.props;
    if (e) {
      e.preventDefault();
    }
    if (onBeforeClose) {
      onBeforeClose(e);
    }
  }

  escFunction = (e) => {
    const { onBeforeClose } = this.props;
    if (e.keyCode === 27) {
      onBeforeClose(e);
    }
  }

  componentDidUpdate() {
    const { onBeforeClose } = this.props;
    const that = this;
    if (onBeforeClose) {
      window.onpopstate = () => {
        that.props.onBeforeClose();
      };
    }
  }

  componentDidMount() {
    document.addEventListener('keyup', this.escFunction, false);
  }

  render() {
    let modal = null;
    const {
      isOffset, hideCloseButton, children, isOpen, showLogo, theme, wideMode,
    } = this.props;
    if (isOpen) {
      const css = 'body {overflow:hidden}';
      modal = (
        <div className={isOffset ? 'modal-overlay -offset -is-open' : 'modal-overlay -is-open'}>
          <div className={wideMode ? 'modal-container -wide' : 'modal-container'}>
            <div className="modal-box" onClick={(e) => { e.stopPropagation(); }}>
              <div className={hideCloseButton ? 'modal-box__inner -mini' : 'modal-box__inner'}>
                {children}
              </div>
              { !hideCloseButton
                && (
                <a className="modal__close" href="#" onClick={(e) => this.closeModal(e)}>
                  <img src="https://assets.heyethos.com/global/icon-close-dark.svg" alt="Close modal" />
                </a>
                )}
              {showLogo
              && (
              <div className="modal-logo">
                <img src={process.env.LARGE_LOGO} alt="ethos" />
              </div>
              )}
            </div>
          </div>
          <style>{css}</style>
        </div>
      );
    }
    return modal;
  }
}

SimpleModal.propTypes = {
  isOpen: PropTypes.bool,
  onBeforeClose: PropTypes.func,
  children: PropTypes.string,
  theme: PropTypes.string,
  isOffset: PropTypes.string,
  hideCloseButton: PropTypes.string,
  showLogo: PropTypes.bool,
};
