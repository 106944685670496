/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import AccountManager from '../../managers/Account';

function ClassicSixCustomBenefits({ onReferFriend, onShareLink, features }) {
  const [announcements, setAnnouncements] = useState([]);
  const [membershipFeatures, setMembershipFeatures] = useState([]);

  useEffect(() => {
    const availableFeatures = [];
    if (features && features.length > 0) {
      features.forEach((feature) => {
        availableFeatures.push(feature.featureName);
      });
      setMembershipFeatures(availableFeatures);
    }
  }, [features]);

  return (
    <div className="benefits-summary">
      <div className="benefits-summary__header">
        <div className="benefits-summary__header--title">
          Earn Points
        </div>
      </div>

      <div className="benefits-highlighted">
        {membershipFeatures.includes('Referrals')
        && (
        <div className="benefits-highlighted__item">
          <img className="benefits-highlighted__icon" src="https://assets.heyethos.com/classicsix/ui/icons/icon-friends.png" alt="Refer a friend" />
          <div className="benefits-highlighted__title">Refer your friends to join Circle</div>
          <div className="benefits-highlighted__description">200 points</div>
          <div className="benefits-highlighted__cta">
            <button
                type="button"
                className="button-collection"
                onClick={() => onReferFriend()}>
              Refer
            </button>
          </div>
        </div>
        )}
        <div className="benefits-highlighted__item">
          <img className="benefits-highlighted__icon" src="https://assets.heyethos.com/classicsix/ui/icons/icon-purchase.png" alt="Make a purchase" />
          <div className="benefits-highlighted__title">Make a purchase</div>
          <div className="benefits-highlighted__description">1 point for every $1 you spend</div>
          <div className="benefits-highlighted__cta">
            <a href={process.env.CLIENT_WEBSITE} target="_blank" className="button-collection" rel="noreferrer">Shop</a>
          </div>
        </div>
        {membershipFeatures.includes('ShopReferrals')
        && (
        <div className="benefits-highlighted__item">
          <img className="benefits-highlighted__icon" src="https://assets.heyethos.com/classicsix/ui/icons/icon-share.png" alt="Share link to shop" />
          <div className="benefits-highlighted__title">Earn points every time your link is shopped</div>
          <div className="benefits-highlighted__description">100 points</div>
          <div className="benefits-highlighted__cta">
            <button
                type="button"
                className="button-collection"
                onClick={() => onShareLink()}>
              Share
            </button>
          </div>
        </div>
        )}

        {membershipFeatures.includes('BirthdayPoints')
        && (
        <div className="benefits-highlighted__item">
          <img className="benefits-highlighted__icon" src="https://assets.heyethos.com/classicsix/ui/icons/icon-birthday.png" alt="Celebrate your birthday" />
          <div className="benefits-highlighted__title">Celebrate your birthday</div>
          <div className="benefits-highlighted__description">200 points</div>
          {AccountManager.hasBirthdate()
            ? (
              <div className="benefits-highlighted__cta">
                <img className="benefits-birthday__img" src="https://assets.heyethos.com/ethos/v2/ui/icon-check-dark.png" alt="Successfully added your birthday" />
              </div>
            )
            : (
              <div className="benefits-highlighted__cta">
                <a href="/account/details" className="button-collection">Add your birthday</a>
              </div>
            )}
        </div>
        )}
      </div>

      <div className="benefits-highlighted -coming-soon">
        <div className="benefits-highlighted__item">
          <img className="benefits-highlighted__icon" src="https://assets.heyethos.com/classicsix/ui/icons/icon-review.png" alt="Submit review" />
          <div className="benefits-highlighted__title">Submit a written review</div>
          <div className="benefits-highlighted__description">100 points</div>
          <div className="benefits-highlighted__cta">
            (Coming soon)
          </div>
        </div>

        <div className="benefits-highlighted__item">
          <img className="benefits-highlighted__icon" src="https://assets.heyethos.com/classicsix/ui/icons/icon-mailing-list.png" alt="Sign up to mailing list" />
          <div className="benefits-highlighted__title">Sign up for mailing list</div>
          <div className="benefits-highlighted__description">100 points</div>
          <div className="benefits-highlighted__cta">
            (Coming soon)
          </div>
        </div>

        <div className="benefits-highlighted__item">
          <img className="benefits-highlighted__icon" src="https://assets.heyethos.com/classicsix/ui/icons/icon-tag-instagram.png" alt="Tag or follow us" />
          <div className="benefits-highlighted__title">Tag or follow us on Instagram</div>
          <div className="benefits-highlighted__description">100 points</div>
          <div className="benefits-highlighted__cta">
            (Coming soon)
          </div>
        </div>
      </div>

      <div className="benefits-summary__header">
        {/* <div className="benefits-summary__header--title">
          Benefits
        </div> */}
      </div>
    </div>
  );
}

export default ClassicSixCustomBenefits;
