const isIOS = () => /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

const isMobile = () => /iPad|iPhone|iPod|Android/.test(navigator.userAgent) && !window.MSStream;

const getParameterByName = function (name, url) {
  let newName = name;
  let newUrl = url;
  if (!url) newUrl = window.location.href;
  newName = name.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + newName + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(newUrl);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

const getBlockchainType = function (chainId) {
  let chainname = 'Flow';
  if (!chainId) {
    chainname = 'Flow';
  } else if (chainId === 0) {
    chainname = 'Flow';
  } else if (chainId === 1) {
    chainname = 'Ethereum';
  } else if (chainId === 2) {
    chainname = 'Polygon';
  }
  return chainname;
};

const getUtilityType = function (type) {
  let utilityName = 'Coupon Voucher';
  if (!type || type === 1) {
    utilityName = 'Coupon Voucher';
  } else if (type === 2) {
    utilityName = 'Video Content';
  } else if (type === 3) {
    utilityName = 'Gated Commerce';
  } else if (type === 4) {
    utilityName = 'Generic Utility';
  } else if (type === 5) {
    utilityName = 'Events';
  } else if (type === 6) {
    utilityName = 'Contests';
  }
  return utilityName;
};

const truncateString = function (ogString, length) {
  let len;
  if (!length) {
    len = 100;
  } else {
    len = length;
  }
  if (ogString.length <= len) {
    return ogString;
  }
  return ogString.substring(0, len) + '...';
};

const getScanUrl = function (nft) {
  const flowscanUrl = process.env.BLOCKCHAIN_ENVIRONMENT === 'test' ? 'https://testnet.flowview.app/account/' : 'https://www.flowview.app/account/';
  const polyscanUrl = process.env.BLOCKCHAIN_ENVIRONMENT === 'test' ? 'https://mumbai.polygonscan.com/' : 'https://polygonscan.com/';
  const ethscanUrl = process.env.BLOCKCHAIN_ENVIRONMENT === 'test' ? 'https://goerli.etherscan.io/' : 'https://etherscan.io/';
  let scanLink;

  if (nft.asset.blockchain === 0) {
    scanLink = flowscanUrl + nft.contractAddress + '/collection';
  } else if (nft.asset.blockchain === 1) {
    scanLink = ethscanUrl + 'token/' + nft.contractAddress + '?a=' + nft.tokenId;
  } else if (nft.asset.blockchain === 2) {
    scanLink = polyscanUrl + 'token/' + nft.contractAddress + '?a=' + nft.tokenId;
  } else {
    scanLink = flowscanUrl + nft.contractAddress + '/collection';
  }
  return scanLink;
};

const getScanName = function (blockchainId) {
  let scannerName;
  if (blockchainId === 0) {
    scannerName = 'flowscan';
  } else if (blockchainId === 1) {
    scannerName = 'etherscan';
  } else if (blockchainId === 2) {
    scannerName = 'polyscan';
  }
  return scannerName;
};

const getUtilityDescription = function (utility) {
  const description = 'Generic description here...';
  if (utility.utilityType === 1) {
    return utility.vouchers[0].description;
  }
  if (utility.utilityType === 2) {
    return utility.gatedContents[0].description;
  }
  if (utility.utilityType === 3) {
    return utility.gatedCommerces[0].ecommerceProductDescription;
  }
  if (utility.utilityType === 4) {
    return utility.genericPromotions[0].description;
  }
  if (utility.utilityType === 5) {
    return utility.events[0].description;
  }
  if (utility.utilityType === 6) {
    return utility.contests[0].description;
  }
  return description;
};

const getUtilityIcon = function (utilityType) {
  if (utilityType === 1) {
    // voucher icon
    return 'https://assets.heyethos.com/ethos/v2/ui/icon-mini-voucher.svg';
  }
  if (utilityType === 2) {
    // video icon
    return 'https://assets.heyethos.com/ethos/v2/ui/icon-mini-gated-content.svg';
  }
  if (utilityType === 3) {
    // commerce icon
    return 'https://assets.heyethos.com/ethos/v2/ui/icon-discount.png';
  }
  if (utilityType === 4) {
    // generic icon
    return 'https://assets.heyethos.com/ethos/v2/ui/icon-discount.png';
  }
  return 'https://assets.heyethos.com/ethos/v2/ui/icon-discount.png';
};

export { isIOS };
export { isMobile };
export { getParameterByName };
export { getUtilityType };
export { getBlockchainType };
export { truncateString };
export { getScanUrl };
export { getScanName };
export { getUtilityDescription };
export { getUtilityIcon };
